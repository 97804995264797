<template>
  <Dropdown
    v-model="shown"
    :disabled
    :container
    :placement
    :popper-class
    @hide="handleHide"
    @escape="handleEscape"
  >
    <template #default="{ show, hide, toggle, icon }">
      <slot :show :hide :toggle :icon :is-dropdown-active />
    </template>
    <template #popper="slotProps">
      <slot v-if="$slots.popper" name="popper" v-bind="slotProps" />
      <div
        v-else
        class="ui-dropdown-list__container"
        ref="containerRef"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <slot name="before" v-bind="slotProps" />
        <DropdownListItem
          v-for="(item, index) in data"
          :data-refid="`dropdownItem${index + 1}`"
          :key="item.value"
          :item
          :current-value
          :selected="selectedIndex === index"
          @click="handleClickItem"
        >
          <template #default="childSlotProps">
            <slot
              name="item"
              :item
              :hide="slotProps.hide"
              v-bind="childSlotProps"
            />
          </template>
        </DropdownListItem>
        <slot name="after" v-bind="slotProps" />
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  useTemplateRef,
  watch,
} from 'vue'
import { Placement } from 'floating-vue'

import { DropdownListData, DropdownListSize } from './utils/types'

import { DELIMETER_KEYWORD } from './utils/const'

import Dropdown from './Dropdown.vue'
import DropdownListItem from './DropdownListItem.vue'

type Props = {
  data: DropdownListData[]
  currentValue?: DropdownListData['value']

  size?: DropdownListSize
  popperClass?: string | Record<string, boolean>

  disabled?: boolean

  container?: string
  placement?: Placement

  native?: boolean
}

type Emits = {
  hide: []
  'click:escape': []
  'click:item': [data: DropdownListData['value'], event: Event]
  'popper:mouseenter': [event: Event]
  'popper:mouseleave': [event: Event]
  'is:end-of-list': [unselect: () => void, resume: () => void]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const shown = defineModel<boolean>({ default: false })

const containerRef = useTemplateRef('containerRef')

const selectedIndex = ref<number>()

const popperClass = computed(() => {
  return {
    [`ui-dropdown-list__popper--${props.size}`]: props.size,
    ...(typeof props.popperClass === 'object'
      ? props.popperClass
      : { [`${props.popperClass}`]: true }),
  }
})

const isDropdownActive = computed(() => selectedIndex.value !== undefined)

const handleClickItem = (value: string, event: Event) => {
  shown.value = false
  emit('hide')
  emit('click:item', value, event)
}

const handleHide = () => {
  emit('hide')
}

const handleEscape = () => {
  emit('click:escape')
}

const scrolToActive = () => {
  setTimeout(() => {
    const el =
      containerRef.value?.querySelector('.ui-dropdown-list-item--selected') ||
      containerRef.value?.querySelector('.ui-dropdown-list-item--active')
    el?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
  }, 50)
}

const unselectFn = () => {
  selectedIndex.value = undefined
}

const resumeFn = () => {
  selectedIndex.value = props.data.length - 1
}

const handleListNavigation = (direction: number) => {
  if (direction > 0) {
    if (props.data.length === 0) {
      emit('is:end-of-list', unselectFn, unselectFn)
      return
    }
    if (selectedIndex.value === props.data.length - 1) {
      emit('is:end-of-list', unselectFn, resumeFn)
      return
    }
  }
  if (selectedIndex.value === 0 && direction < 0) {
    return
  }
  if (selectedIndex.value === undefined) {
    selectedIndex.value = 0
  } else {
    selectedIndex.value += direction
  }
  if (props.data[selectedIndex.value].value === DELIMETER_KEYWORD) {
    handleListNavigation(direction)
    return
  }
  scrolToActive()
}

const handleMouseEnter = (event: Event) => {
  emit('popper:mouseenter', event)
}

const handleMouseLeave = (event: Event) => {
  emit('popper:mouseleave', event)
}

const handleKeyDown = (e: KeyboardEvent) => {
  if (!shown.value || props.native || props.disabled) return
  switch (e.key) {
    case 'Tab':
      selectedIndex.value = 0
      e.stopPropagation()
      e.preventDefault()
      break
    case 'ArrowDown':
      e.preventDefault()
      handleListNavigation(1)
      break
    case 'ArrowUp':
      e.preventDefault()
      handleListNavigation(-1)
      break
    case 'Enter':
      if (
        selectedIndex.value !== undefined &&
        props.data[selectedIndex.value]
      ) {
        e.preventDefault()
        e.stopPropagation()
        handleClickItem(props.data[selectedIndex.value].value, e)
      }
      break
  }
}

watch(containerRef, value => {
  if (!value) {
    selectedIndex.value = undefined
    return
  }
  scrolToActive()
})

watch(
  () => props.data,
  () => {
    selectedIndex.value = undefined
  },
)

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeyDown)
})
</script>

<style>
.ui-dropdown-list {
  &__container {
    @apply -my-1;
    @apply max-h-96;
    @apply overflow-y-auto;
  }
}
</style>
