<template>
  <div
    class="ui-dropdown-list-item ui-dropdown-actions-item"
    :class="mainClasses"
    @click="handleClick"
  >
    <component
      class="ui-dropdown-list-item__icon"
      :is="item.meta?.icon"
      aria-hidden="true"
    />
    <span>{{ item.label }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { DropdownListData } from './utils/types'

type Props = {
  item: DropdownListData
  currentValue?: DropdownListData['value']
  selected?: boolean
}

type Emits = {
  (e: 'click', value: string): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const isActive = computed(() => props.item.value === props.currentValue)

const mainClasses = computed(() => ({
  'ui-dropdown-list-item--active': isActive.value,
  'ui-dropdown-list-item--selected': props.selected,
  [`ui-dropdown-actions-item--${props.item.meta?.variant}`]:
    props.item.meta?.variant,
}))

const handleClick = () => {
  emit('click', props.item.value)
}
</script>

<style>
.ui-dropdown-list-item {
  @apply flex items-center;
  @apply gap-2;

  &__icon {
    @apply -ml-1;
    @apply size-4;
  }
}

.ui-dropdown-actions-item {
  @apply text-xs;

  &--danger {
    @apply !text-red-600 dark:!text-red-400;
  }
}
</style>
