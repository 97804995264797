<template>
  <DropdownList
    v-model="shown"
    :data
    :placement
    :disabled
    popper-class="ui-dropdown-actions-popper"
    @click:item="handleClickItem"
  >
    <template #default="slotProps">
      <slot v-bind="slotProps" />
    </template>
    <template #before="slotProps">
      <slot name="before" v-bind="slotProps" />
    </template>
    <template v-if="$slots.popper" #popper="slotProps">
      <slot name="popper" v-bind="slotProps" />
    </template>
    <template #item="{ item, isSelected, dataRefid }">
      <DropdownActionsItem
        :item
        :current-value
        :selected="isSelected"
        :data-refid
        @click="handleClickItem"
      />
    </template>
    <template #after="slotProps">
      <slot name="after" v-bind="slotProps" />
    </template>
  </DropdownList>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Placement } from 'floating-vue'

import { DropdownActionsData } from './utils/types'

import DropdownList from './DropdownList.vue'
import DropdownActionsItem from './DropdownActionsItem.vue'

type Props = {
  data: DropdownActionsData[]
  currentValue?: DropdownActionsData['value']

  disabled?: boolean

  container?: string
  placement?: Placement
}

type Emits = {
  'click:item': [value: string]
}

const { placement = 'bottom-end', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const shown = defineModel<boolean>({ default: false })

const data = computed(() =>
  props.data.map(item => ({
    value: item.value || item.label,
    label: item.label,
    meta: {
      icon: item.icon,
      action: item.action,
      variant: item.variant,
    },
  })),
)

const handleHide = () => {
  shown.value = false
}

const handleClickItem = (value: string) => {
  const item = props.data.find(item => item.label === value)
  item?.action?.(handleHide) || emit('click:item', value)
}
</script>

<style>
.ui-dropdown-actions-popper {
  .v-popper__inner {
    @apply min-w-40;
  }

  .v-popper__arrow-container {
    @apply hidden;
  }
}
</style>
