<template>
  <Dropdown
    ref="dropdownRef"
    v-model:shown="shown"
    :disabled
    :triggers="[]"
    strategy="fixed"
    theme="dropdown"
    :auto-hide="false"
    auto-size
  >
    <slot :show :hide :toggle :icon :is-shown="shown" />
    <template #popper>
      <div ref="popperRef">
        <slot name="popper" :show :hide :toggle />
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { computed, nextTick, useTemplateRef } from 'vue'
import { Dropdown } from 'floating-vue'
import { onClickOutside } from '@vueuse/core'

import { useHotKeys } from '@/plugins/hotKeys'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'

type Props = {
  disabled?: boolean
}

type Emits = {
  hide: []
  escape: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const shown = defineModel<boolean>({ default: false })

const dropdownRef = useTemplateRef<any>('dropdownRef')
const popperRef = useTemplateRef('popperRef')

const icon = computed(() => (shown.value ? ChevronUpIcon : ChevronDownIcon))

const toggle = () => {
  shown.value = !shown.value
  !shown.value &&
    nextTick(() => {
      emit('hide')
    })
}

const show = () => {
  shown.value = true
}

const hide = () => {
  shown.value = false
  emit('hide')
}

onClickOutside(dropdownRef, e => {
  if (!shown.value) return
  const path = (e as any).path || (e.composedPath && e.composedPath())
  if (path.find((item: HTMLElement) => item === popperRef.value)) return
  shown.value = false
})

useHotKeys(
  'escape',
  e => {
    e?.stopPropagation()
    if (!shown.value) {
      emit('escape')
      return
    }
    shown.value = false
    hide()
    return true
  },
  { priority: 100 },
)
</script>
