<template>
  <div
    v-if="item.value === DELIMETER_KEYWORD"
    class="ui-dropdown-list-item--delimeter"
  />
  <slot
    v-else
    :is-active
    :is-selected="selected"
    :item-classes
    :data-refid
    :on-click="handleClickItem"
  >
    <div
      class="ui-dropdown-list-item"
      :class="itemClasses"
      :data-refid="dataRefid"
      @click="handleClickItem"
    >
      {{ item.label }}
    </div>
  </slot>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { DropdownListData } from '@types'
import { DELIMETER_KEYWORD } from './utils/const'

type Props = {
  item: DropdownListData

  dataRefid?: string

  currentValue?: DropdownListData['value']
  selected?: boolean
}

type Emits = {
  click: [data: DropdownListData['value'], event: MouseEvent]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const isActive = computed(() => props.item.value === props.currentValue)

const itemClasses = computed(() => ({
  'ui-dropdown-list-item--active': isActive.value,
  'ui-dropdown-list-item--selected': props.selected,
}))

const handleClickItem = (event: MouseEvent) => {
  emit('click', props.item.value, event)
}
</script>

<style>
@import './assets/styles/colors.css';

.ui-dropdown-list-item {
  @apply px-4 py-2;
  @apply text-[var(--text-color)];
  @apply hover:text-[var(--hover-color)];
  @apply hover:bg-[var(--hover-bg)];
  @apply cursor-pointer;

  .ui-dropdown-list__popper--xxs &,
  .ui-dropdown-list__popper--xs &,
  .ui-dropdown-list__popper--sm & {
    @apply text-xs;
  }

  .ui-dropdown-list__popper--md &,
  .ui-dropdown-list__popper--lg &,
  .ui-dropdown-list__popper--xl & {
    @apply text-sm;
  }

  &--active {
    @apply text-[var(--active-color)];
    @apply bg-[var(--active-bg)];
  }

  &--selected {
    @apply text-[var(--selected-color)];
    @apply !bg-[var(--selected-bg)];
  }

  &--delimeter {
    @apply -mb-[1px];
    @apply border-t border-gray-200 dark:border-gray-700;
  }
}
</style>
